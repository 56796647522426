import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import logoFvf from '../images/fvf-logo.svg'
import logoMs from '../images/ms-logo.svg'

const Imprint = ({
  data: {
    wordpressPage: { title, content },
  },
}) => (
  <Layout>
    <article className="imprint">
      <div className="container">
        <div className="row middle-xs">
          <div className="col-xs-7">
            <h1 className="imprint__title">{title}</h1>
          </div>
          <div className="col-xs-2 col-md-1">
            <img src={logoFvf} alt="FvF Logo" width="100%" height="100%" />
          </div>
          <div className="col-xs-2 col-md-1">
            <img src={logoMs} alt="MoreSleep Logo" width="100%" height="100%" />
          </div>
        </div>
        <div
          className="row imprint__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </article>
  </Layout>
)

export default Imprint

export const pageQuery = graphql`
  query {
    wordpressPage(id: { eq: "4ddc7154-859c-5256-bc0a-6843066d3cab" }) {
      title
      content
    }
  }
`
